import React from 'react';
import  GlobalStyle  from './global-styles';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom'
// import Navbar from "./components/Navbar/Navbar";
// import './App.css';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import Products from './pages/Products/Products';
import SignUp from './pages/Signup/Signup';
import Login from './pages/SignIn/Login';
// import ContactUs from './pages/ContactUs/ContactUs';
// import {FooterContainer} from './containers/footer';
import ScrollToTop from './components/ScrollToTop';
import { Navbar, Footer } from './components';


function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/products' element={<Products />} />
          {/* <Route path='/contact-us' element={<ContactUs />} /> */}
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/sign-in' element={<Login />} />
        </Routes>
        {/* <Home /> */}
      {/* <FooterContainer /> */}
      <Footer />
    </Router>
  );
}

export default App;
