import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../../context/AuthProvider";
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../global-styles';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink
} from './Navbar.elements';

function Navbar() {

  const userAuth = useContext(AuthContext);
  const { setAuth } = useContext(AuthContext);

  const logout = async () => {
  //   await fetch('http://localhost:45545/users/logout', {
  //   method: 'POST',
  //   headers: {'Content-Type': 'application/json'},
  //   credentials: 'include'
  // });
  
  setAuth({}); 
  }

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const[name, setName] = useState('');

  useEffect(() => {
    (
       async () => {
      //   const response = await fetch('http://localhost:45545/users/getall', 
      //   {
      //     headers: {'Content-Type': 'application/json'},
      //     credentials: 'include',
      //   });

      //   const content = await response.json();
        // console.log(content[0].FullName);
        // setName(content[0].FullName);

        //console.log(userAuth.auth);

        if(userAuth.auth.user !== undefined)
        {
          setName(userAuth.auth.user);
          //console.log(userAuth.auth.user);
        }
        else 
        {
          setName('');
        }

        displayHi();
      }
    )();
  });

  const displayHi = () => {
    if(name !== "")
    {
      setLoggedIn(true);
    }
    else
    {
      setLoggedIn(false);
    }
  }

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to='/' onClick={closeMobileMenu}>
              <NavIcon />
              Clean Code Engineer
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/' onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/services' onClick={closeMobileMenu}>
                  Services
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/products' onClick={closeMobileMenu}>
                  Courses
                </NavLinks>
              </NavItem>
              {/* <NavItemBtn>
                {button ? (
                  <NavBtnLink to='/sign-up'>
                    <Button primary>SIGN UP</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to='/sign-up'>
                    <Button onClick={closeMobileMenu} fontBig primary>
                      SIGN UP
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn> */}
              {loggedIn ? (
                <>
                    <NavItem>
                        <NavLinks to='/' onClick={closeMobileMenu}>
                          Hi, {name}
                        </NavLinks>
                    </NavItem>

                    <NavItemBtn>
                        <NavBtnLink to='/sign-in'>
                          <Button primary onClick={logout}>Logout</Button>
                        </NavBtnLink>
                    </NavItemBtn>
                </>
                ) : (
                <NavItemBtn>    
                  {button ? (
                    <NavBtnLink to='/sign-in'>
                      <Button primary>SIGN IN</Button>
                    </NavBtnLink>
                  ) : (
                    <NavBtnLink to='/sign-in'>
                      <Button onClick={closeMobileMenu} fontBig primary>
                        SIGN IN
                      </Button>
                    </NavBtnLink>
                  )}
                </NavItemBtn>
                )}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
