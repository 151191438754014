import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne, homeObjTwo } from './Data';
import CourseComponent from '../../components/InfoSection/CourseComponent';


function Products() {
  return (
    <>
      <InfoSection {...homeObjOne} />
      {/* <InfoSection {...homeObjTwo} /> */}
      <CourseComponent />
    </>
  );
}

export default Products;
