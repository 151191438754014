import React, { useRef, useState, useEffect } from 'react';
//import { Navigate } from 'react-router-dom'
import { SignupContainer, SignupSubscription, SignupSubHeading, Form, FormInput } from "./Signup.elements";
import { Button } from '../../global-styles';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SignUp.css'

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const FULLNAME_REGEX = /^([\w]{3,})+\s+([\w\s]{3,})+$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//function SignUp() {
const SignUp = () => {
  const fullnameRef = useRef();
  const userRef = useRef();
  const errRef = useRef(); 

  const [fullname, setFullname] = useState('');
  const [validFullName, setValidFullName] = useState(false);
  const [fullnameFocus, setFullnameFocus] = useState(false);

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  //const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    fullnameRef.current.focus();
  }, [])

  useEffect(() => {
    const result = FULLNAME_REGEX.test(fullname);
    console.log(result);
    console.log(fullname);
    setValidFullName(result);
  }, [fullname])

  useEffect(() => {
    const result = USER_REGEX.test(user);
    console.log(result);
    console.log(user);
    setValidName(result);
  }, [user])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    console.log(result);
    console.log(email);
    setValidEmail(result);
  }, [email])

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    console.log(result);
    console.log(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('');
  }, [user, email, fullname, pwd, matchPwd])

  const handleSubmit = async (e) =>
  {
    e.preventDefault();

    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(email);
    const v4 = FULLNAME_REGEX.test(fullname);

    if(!v1 || !v2 || !v3 || !v4)
    {
      setErrMsg("Invalid Entry");
      return;
    }
    
    // http://localhost:45545/users/register
    try {
      const response = await fetch('https://webapicleancodeengineer.azurewebsites.net/users/register', 
      {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
              username: user, emailaddress: email, password: pwd, fullname: fullname
          })
      });

      const content = await response.json();
            const accessToken = content.Token;
            console.log(accessToken);
            
            console.log(response?.status);

            if(response?.status === 200)
            {
                setSuccess(true);
                // clear state and controlled inputs
                // need value attrib on inputs for this
                // setUser('');
                // setPwd('');
                // setMatchPwd('');
            }
            else if(response?.status === 409)
            {
                setSuccess(false);
                //setErrMsg(content.Message);
                setErrMsg('Username Taken');
            }
            else {
                setSuccess(false);
                setErrMsg('Registration Failed');
            }
            errRef.current.focus();

      //setRedirect(true);
  }
  catch (err) 
  {
  //console.log(err?.response);
    if(!err?.response) {
        setErrMsg('No Server Response');
        //errRef.current.focus();
    }           
  }
}

  // if(redirect)
  // {
  //   return <Navigate to= "/sign-in"/>;
  // }

  return (
    <>
    {success ? (
      <section>
        <h1>Success!</h1>
        <p>
          <a href="sign-in">Sign In</a>
        </p>
      </section>
    ) : (
      <section>
        <SignupContainer>
          <SignupSubscription>
            <SignupSubHeading>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <span>Please register: </span>
            </SignupSubHeading>

            <Form onSubmit={handleSubmit}>
              {/* <h1 className="h3 mb-3 fw-normal">Please register</h1> */}   
              <FormInput  
              type="text" 
              id="fullname" 
              ref={fullnameRef} 
              name="fullname" 
              placeholder="Full Name"
              autoComplete="off" 
              className="form-control" 
              required
              onChange={e => setFullname(e.target.value)} 
              aria-invalid={validFullName ? "false" : "true"}
              aria-describedby="fullnamenote"
              onFocus={() => setFullnameFocus(true)}
              onBlur={() => setFullnameFocus(false)} 
              />
              <span className={validFullName ? "valid" : "hide"}>
                 <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className={validFullName || !fullname ? "hide" : "invalid"}>
                 <FontAwesomeIcon icon={faTimes} />
              </span>
              <p id="fullnamenote" className={fullnameFocus && fullname && !validFullName ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.<br />
              </p>

              <FormInput  
              type="text" 
              id="username" 
              ref={userRef} 
              name="username" 
              placeholder="Username"
              autoComplete="off" 
              className="form-control" 
              required
              onChange={e => setUser(e.target.value)} 
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)} 
              />
              <span className={validName ? "valid" : "hide"}>
                 <FontAwesomeIcon icon={faCheck} />
              </span>
              <span className={validName || !user ? "hide" : "invalid"}>
                 <FontAwesomeIcon icon={faTimes} />
              </span>
              <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.<br />
                Must begin with a letter.<br />
                Letters, numbers, underscores, hyphens allowed.
              </p>
              
                <FormInput 
                type="email" 
                id="email" 
                name="email" 
                className="form-control" 
                placeholder="Email address" 
                required 
                onChange={e => setEmail(e.target.value)}
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                />
                <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />

              <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Email address is invalid.
              </p>

             
                <FormInput 
                name="password" 
                type="password" 
                id="password" 
                className="form-control" 
                placeholder="Password" 
                required 
                onChange={e => setPwd(e.target.value)}
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                />
                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />    
       
              <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.<br />
                Must include uppercase and lowercase letters, a number and a special character.<br />
                Allowed special characters: <span aria-label="exclamation mark">!</span>
                <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
              </p>
                          
                <FormInput 
                name="confirmPassword" 
                type="password" 
                id="confirmPassword" 
                className="form-control" 
                placeholder="Confirm Password" 
                required 
                onChange={e => setMatchPwd(e.target.value)}
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)} 
                />
                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
      

              <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the first password input field.
              </p>

              <Button 
              //disabled={!validName || !validEmail || !validPwd || !validMatch ? true : false} 
              className="w-100 btn btn-lg btn-primary" type="submit">Sign Up</Button>
            </Form>
            <p>
              Already registered?<br />
              <span>
                <a href="sign-in">Sign In</a>
              </span>
            </p>
          </SignupSubscription>
        </SignupContainer>
      </section>
      )}
    </>
  )
}

export default SignUp;
