import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import { LoginContainer, LoginSubscription, LoginSubHeading, LoginLinksContainer, LoginLinksWrapper, LoginLinkItems, LoginLinkTitle, LoginLink, Form, FormInput } from "./Login.elements";
import { Button } from '../../global-styles';
import { Navigate } from "react-router-dom";
import './Login.css';

const Login = () => {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        // http://localhost:45545/users/login
        try {
                const response =  await fetch('https://webapicleancodeengineer.azurewebsites.net/users/login',
                {
                    method:'POST',
                    headers: {'Content-Type': 'application/json'},
                    // credentials: 'same-origin',
                    credentials: 'include',
                    body: JSON.stringify({
                        username: user, password: pwd
                    })
                });

                const content = await response.json();
                console.log(content.FullName);

                const accessToken = content.Token;
                console.log(accessToken);
                
                // We store all of these information in the Auth object in the global context
                setAuth({ user, pwd, accessToken }); 
                setUser('');
                setPwd('');

                console.log(response?.status);

                if(response?.status === 200)
                {
                    setSuccess(true);
                }
                else if(response?.status === 400)
                {
                    setSuccess(false);
                    setErrMsg(content.Message);
                    //setErrMsg('Incorrect Username or Password');
                    //setErrMsg(response?.data);
                }
                else if(response?.status === 401)
                {
                    setSuccess(false);
                    setErrMsg('Unauthorized');
                }
                else {
                    setSuccess(false);
                    setErrMsg('Login Failed');
                }
                errRef.current.focus();
        }
        catch (err) 
        {
            //console.log(err?.response);
                if(!err?.response) {
                    setErrMsg('No Server Response');
                    //errRef.current.focus();
                }           
            }
        }

    if(success)
    {
        return <Navigate to="/" />;
    }

    return (
        <>
            <LoginContainer>
                <LoginSubscription>
                    <LoginSubHeading>
                        {/* <section style={{backgroundColor: "#63C5FF"} }> */}
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                        <span>Please enter your Username and Password:</span>
                    </LoginSubHeading>
                    {/* <form onSubmit={handleSubmit}> */}
                    <Form onSubmit={handleSubmit}>
                        {/* <label htmlFor="username">Username:</label> */}
                        {/* <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required /> */}
                        <FormInput name='username' placeholder='Username' type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
                        {/* <label htmlFor="password">Password:</label> */}
                        {/* <input type="password" id="password"  onChange={(e) => setPwd(e.target.value)} value={pwd} required /> */}
                        <FormInput name='password' placeholder='password' type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                        {/* <button>Sign In</button> */}
                        <Button fontBig>Sign In</Button>
                    </Form>
                </LoginSubscription>
                <LoginLinksContainer>
                    <LoginLinksWrapper>
                        <LoginLinkItems>
                            <LoginLinkTitle>Need an Account?</LoginLinkTitle>
                            {/* Need an Account?<br /> */}
                            {/*put router link here*/}
                            {/* <a href="#">Sign Up</a> */}
                            <LoginLink to='/sign-up'>Sign Up</LoginLink>
                            {/* <Button fontBig>Sign Up</Button> */}
                        </LoginLinkItems>
                    </LoginLinksWrapper>
                </LoginLinksContainer>
                {/* </form> */}       
            {/* </section> */}              
            </LoginContainer>
        </>
    )
}

export default Login