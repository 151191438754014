import React, { useEffect, useState } from 'react';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import { InfoSection, Pricing } from '../../components';

function Home() {
  return (
    <>
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      {/* <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjFour} /> */}

      {/* <Pricing /> */}
    </>
  );
}

export default Home;
