export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Online Trainings for Developers',
    headline: 'Best practices for software developement',
    description:
      'We help developers to learn the latest trends. Our team of unique specialist can help you achieve your career goals.',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-3.svg').default,
    alt: 'Credit Card',
    start: ''
  };  

  export const homeObjTwo = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Interview Questions',
    headline: 'Practice the important questions with us',
    description:
      'You will never have to worry about missing a topic. Our team will ensure your reviewed everything before your interview.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-2.svg').default,
    alt: 'Vault',
    start: 'true'
  };


  export const homeObjThree = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-1.svg').default,
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Sarah Tash',
    headline:
      'I have masters in Software Engineering and 9 years of experience!',
    description:
      "Our team provide you the knowledge you need in order to get better jobs!",
    buttonLabel: 'View Requirements',
    imgStart: 'start',
    img: require('../../images/300b300.png'),
    alt: 'Vault',
    start: 'true'
  };
