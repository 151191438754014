import React from 'react';
import { Button } from '../../global-styles';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Services</PricingHeading>
          <PricingContainer>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiRock />
                </PricingCardIcon>
                <PricingCardPlan>Basic Concepts</PricingCardPlan>
                <PricingCardCost>$99.99</PricingCardCost>
                <PricingCardLength>per course</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>C# Coding</PricingCardFeature>
                  <PricingCardFeature>Data Structures and</PricingCardFeature>
                  <PricingCardFeature>Algorithms</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCrystalBars />
                </PricingCardIcon>
                <PricingCardPlan>FRONT END</PricingCardPlan>
                <PricingCardCost>$199.99</PricingCardCost>
                <PricingCardLength>per course</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>HTML/HTML5/CSS3</PricingCardFeature>
                  <PricingCardFeature>Java Script/JQuery</PricingCardFeature>
                  <PricingCardFeature>React</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/sign-up'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon>
                <PricingCardPlan>BACKEND</PricingCardPlan>
                <PricingCardCost>$199.99</PricingCardCost>
                <PricingCardLength>per user</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>.Net</PricingCardFeature>
                  <PricingCardFeature>ASP.NET</PricingCardFeature>
                  <PricingCardFeature>MS SQL</PricingCardFeature>
                  </PricingCardFeatures>
                <Button primary>Choose Plan</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;
