import React from 'react';
import "./Products.css"

class CourseComponent extends React.Component{
    constructor(props){
      super(props);
  
      this.state = {
        courses:[]
      };
    }
  
    // http://localhost:45545/course
    componentDidMount(){
      console.log('Component did mount, fetching courses...');
      this.fetchCourses();
    }

    fetchCourses = () => {
      fetch("https://webapicleancodeengineer.azurewebsites.net/course")
        .then(res => {
          if (!res.ok) {
            throw new Error('Network response was not ok ' + res.statusText);
          }
          return res.json();
        })
        .then(
          result => {
            console.log('Courses fetched successfully:', result);
            this.setState({ courses: result });
          },
          error => {
            console.error('Error fetching courses:', error);
          }
        );
    }
  
render(){
  return(
    <div className='product-container'>
      <h2>Courses Details...</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Location</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
        <tr key='Arrays in C# - Data Structure'>
              <td data-label="Name">Arrays in C# - Data Structure</td>
              <td data-label="Description">Fundamentals of defining an <a href='https://youtu.be/zccTxWyWBVs?si=whGi0KaaW0Jr4U7w'>array data structure</a> in C#</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Binary Trees in C# - Data Structure'>
              <td data-label="Name">Binary Trees in C# - Data Structure</td>
              <td data-label="Description">How to create a <a href='https://youtu.be/GezCC1AEpTA?si=_z2SJ-ohULKRCtuv'>binary tree</a> in C#</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Linked List in C# - Data Structure'>
              <td data-label="Name">Linked List in C# - Data Structure</td>
              <td data-label="Description">mastering <a href='https://youtu.be/Ywn02L4JGiU?si=6kZk-8gtSjUgehW1'>singly linked lists</a> in C#</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Stacks in C# - Data Structure'>
              <td data-label="Name">Stacks in C# - Data Structure</td>
              <td data-label="Description">mastering <a href='https://youtu.be/7mpAimaV9ro?si=-ik33rcuXyfL_XPi'>stacks</a> in C#</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Queue in C# - Data Structure'>
              <td data-label="Name">Queue in C# - Data Structure</td>
              <td data-label="Description">Implementation of a generic <a href='https://youtu.be/rzWWunH0lCc?si=PcUX6mtLI98xgX-E'>queue</a> from scratch</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Implementing a Priority Queue using Min-Heap in C# - Data Structure'>
              <td data-label="Name">Implementing a Priority Queue using Min-Heap in C# - Data Structure</td>
              <td data-label="Description">Using a min-heap data structure to maintain the <a href='https://youtu.be/d927sjTEoB0?si=THTLOvxOALwSDo1r'>priority queue</a></td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Understanding the Push Method in a Priority Queue (Min Heap)'>
              <td data-label="Name">Understanding the Push Method in a Priority Queue (Min Heap)</td>
              <td data-label="Description">How the <a href='https://youtu.be/iC7K09UdztI?si=Ey1Bo6TCJdIfDdZE'>Push method</a> works in priority queue</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Understanding the Pop Method in a Priority Queue (Min-Heap)'>
              <td data-label="Name">Understanding the Pop Method in a Priority Queue (Min-Heap)</td>
              <td data-label="Description">understanding the <a href='https://youtu.be/3IhSLlqMh0E?si=7rWf-tGkr0JUcfVj'>Pop method</a> in a Priority Queue implemented using a Min-Heap</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='How to use a Priority Queue using Min-Heap in C#?'>
              <td data-label="Name">How to use a Priority Queue using Min-Heap in C#?</td>
              <td data-label="Description">How to use the <a href='https://youtu.be/I08wu8bG82U?si=QuocPJ3ejcjRU4By'>PriorityQueue</a> class</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Object Oriented Design for a Restaurant Reservation System in C# - Part 1'>
              <td data-label="Name">Object Oriented Design for a Restaurant Reservation System in C# - Part 1</td>
              <td data-label="Description"><a href='https://youtu.be/kwvtxzXvojU?si=hdL-bxqYo4gEuNBq'>Restaurant Reservation System</a> - Part 1</td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Object Oriented Design - Restaurant Reservation System in C# | Part 2: Core Methods Explained'>
              <td data-label="Name">Object Oriented Design - Restaurant Reservation System in C# | Part 2: Core Methods Explained</td>
              <td data-label="Description">Restaurant Reservation System - Part 2: <a href='https://youtu.be/qdtzZ_TGRIU?si=P6lghb0fx387Lrla'>Core Methods</a></td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
        <tr key='Object Oriented Design (Restaurant Reservation System) - Part 3'>
              <td data-label="Name">Object Oriented Design (Restaurant Reservation System) - Part 3</td>
              <td data-label="Description">Restaurant Reservation System - Part 3: <a href='https://youtu.be/gKg9pMwXiKM?si=gAM0OpBaoTIOzXbM'>console application</a></td>
              <td data-label="Location">Youtube</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Available</td>
        </tr>
          {this.state.courses.map(crs=>(
            <tr key={crs.Name}>
              <td data-label="Name">{crs.Name}</td>
              <td data-label="Description">{crs.Description}</td>
              <td data-label="Location">{crs.Location}</td>
              {/*<td data-label="Date">{crs.Date}</td>*/}
              <td data-label="Date">Coming Soon...</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      );
    }
  }
  
  export default CourseComponent;