import CourseComponent from '../../components/InfoSection/CourseComponent';


export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTopLine: false,
    lightTextDesc: false,
    topLine: 'View Our Courses',
    headline: 'Learn through our videos of courses',
    description:
      'We provide videos for our trainings. If there are any issues, just issue a refund and we will process your request',
    buttonLabel: 'Learn Now',
    imgStart: '',
    img: require('../../images/myProfile1.svg').default,
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    topLine: '100% Secure',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-2.svg').default,
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Easy Setup',
    headline: 'Super fast and simple onboarding process',
    description:
      "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: require('../../images/svg-1.svg').default,
    alt: 'Vault'
  };

  

  
  